<!-- 心理咨询配置 -- 咨询时间配置 -- 列表 -->
<template>
  <div>
    <list-template
        :loading="loading"
        :search-config="searchConfig"
        @onSearch="onSearch"
        :table-config="tableConfig"
        :table-data="tableData"
        :total="total"
        @onAdd="$router.push('./add')"
        has-add
        :current-page="page"
        @onChangePage="handleCurrentChange"
        @onHandle="handleEdit"
    ></list-template>
  </div>
</template>

<script>
//引入ajax接口管理层的接口函数
import {deleteRole} from '@/api/role'
import {mapGetters, mapState} from "vuex";
import moment from "moment";

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  data() {
    return {
      loading: true,
      search: {},
      searchConfig: [
        {
          prop: "school_id",
          tag: 'select',
          placeholder: "请选择校区",
          label: 'school_name',
          value: 'id',
          options: [],
        },
        {
          prop: "term_name",
          placeholder: "请输入咨询周期、咨询老师",
          width: '220'
        },
      ],
      tableData: [],
      //总条数
      total: 0,
      pageCount: 0,

      tableConfig: [
        {label: "序号",type: 'index', width: '80',property: {
            index: (index) => {
              return (this.page - 1) * 20 + index + 1;
            }
          }},
        {prop: "term_name", label: "咨询周期"},
        {prop: "school_name", label: "校区"},
        {prop: "term_time", label: "周期时间"},
        {prop: "updated_at", label: "更新时间"},
        {prop: "teacher_name", label: "咨询老师"},
        {prop: "time_count", label: "可咨询时间数"},
        {
          prop: "handle",
          label: "操作",
          width: "120rem",
          handle: true,
          buttons: row => {
            let boo1 = moment(row.term_time.slice(-10)).format('x') >= moment().format('x');
            let boo2 = Number(row.teacher_id) === Number(JSON.parse(sessionStorage.getItem('USER_INFO')).userinfo.teacher_id)
            if(boo1 && boo2) {
              return [{ type:"edit", text:"编辑" }]
            }
            return [{ type:"view", text:"查看" }]
          }
        },
      ],

      historyDialogShow: false,
      historyList: []
    }
  },
  created() {
    this.$store.commit("setPage", 1);
    this.getData()
    this.getSchool()
  },
  activated() {
    this.getData()
  },
  computed: {
    ...mapState(["page"])
  },
  methods: {
    getData() {
      this.loading = true
      let params = {...this.search, page: this.page}
      this.tableData = [];
      this.$_axios2('/api/evaluation/counseling-setting/teacher-schedule', {params}).then(res => {
        // console.log(res)
        let data = res.data.data
        this.tableData = data.list
        this.total = data.page.total
      }).finally(() => {
        this.loading = false
      })
    },

    // 校区数据获取
    getSchool() {
      this.$_axios.get('site/school').then(res => {
        // console.log(res)
        this.searchConfig[0].options = res.data.data;
        this.searchConfig[0].options.unshift({id: "0", school_name: "全部"})
      })
    },

    onSearch(val) {
      this.$store.commit('setPage', 1);
      this.search = val;
      this.getData(val)
    },

    getProvince() {
      this.$_register('/api/recruit/common/area', {params: {address_code: '000000'}}).then(res => {
        this.searchConfig[0].options = res.data.data
      })
    },

    getCity(e) {
      this.$_register('/api/recruit/common/area', {params: {address_code: e}}).then(res => {
        this.searchConfig[1].options = res.data.data
      })
    },

    getArea(e) {
      this.$_register('/api/recruit/common/area', {params: {address_code: e}}).then(res => {
        this.searchConfig[2].options = res.data.data
      })
    },

    handleEdit(row, handle) {
      const { type } = handle
      if (type === 'edit') {
        this.$router.push('./edit?id=' + row.id)
      }

      if (type === 'view') {
        this.$router.push('./details?id=' + row.id)
      }
    },

    // 当前页改变触发
    handleCurrentChange(val) {
      //当前页赋值给currentPage
      this.$store.commit("setPage", val);
      //拉取数据
      this.getData()
    },

  },
}
</script>

<style lang="scss" scoped>

.role-list {
  padding-top: 24rem;
}

.button {
  margin-left: 34rem;
  margin-bottom: 30rem;
  box-sizing: border-box;
  width: 90rem;
  height: 34rem;
  opacity: 1;
  border-radius: 4rem;
}

.table {
  margin-left: 32rem;
}



::v-deep .el-table--enable-row-transition .el-table__body td.el-table__cell {
  text-align: left;
  font-size: 14rem;
  font-family: Microsoft YaHei;
  font-weight: normal;
  height: 48rem;
  box-sizing: border-box;
  color: #333333;
}

.header {
  position: fixed;
  top: 120rem;
  left: 300rem;
  font-size: 16rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 24rem;
  color: #333333;
  letter-spacing: 3.5rem;
}

.el-button--text {
  margin-right: 40rem;
  font-size: 14rem;
  font-family: Microsoft YaHei;
  font-weight: normal;
  color: #3491fa;
}
</style>
